const Map = ()=> {
    return (
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1VEzMOEoAqIv26IaDg9VPrz6N7uqW71w&ehbc=2E312F&noprof=1"
        width= "auto"
        height="450"
        style={{border: 15}}
        className="map"
        allowfullscreen="" 
        frameborder="0"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    );
}

export default Map;
