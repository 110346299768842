import './design.css';

const Footer = () => {
  return (
    <footer >
      <h5>Propiedad del Grupo Credigas Nativa © - República Dominicana</h5>
    </footer>
  );
}

export default Footer;
