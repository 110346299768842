import React from "react";
import './mbSent.css';
import checkMark from '../../resourses/logo/checkMark3.gif';
import errorMark from '../../resourses/logo/errorMark3.png';


function mbSent ({sent} : {sent:Boolean}) {
  return (
    <div className="modal2">
      <div className="modal-content2">
        {sent ? (
          <h2>Solicitud Enviada</h2>
        ) : (
          <h2>Error en Solicitud</h2>
        )}
        {sent ? <img src={checkMark} /> : <img src={errorMark} />}
      </div>
    </div>
  );
};

export default mbSent;