import Header from '../sections/header/Header.js';
import Mainbar from '../sections/mainbar/Mainbar.js';
import CompaniesForm from './CompaniesForm.js';
import Footer from '../sections/footer/Footer.js';

const Companies = () => {
  return (
    <div className="container">
      <Header/>
      <Mainbar />
      <CompaniesForm />
      <Footer />
    </div>
  );
};

export default Companies;