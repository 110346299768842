import CartoonLogo from '../../resourses/logo/cartoonNoPage.png';
import Logo404 from '../../resourses/logo/logo404.png';
import Content404 from '../../resourses/logo/content404.png';
import './design.css';

let NoPage = () => {
    return (
      <div className="main">
        <div>
          <img src={Logo404} alt="logo404" className="l404"></img>
        </div>
        <div>
          <img src={Content404} alt="ContentCartoon" className="c404"></img>
          <img src={CartoonLogo} alt="logoCartoon" className="lCartoon"></img>
        </div>
      </div>
    );
}

export default NoPage;