/*Default*/
const dataSelectDefault = [
    {
      value:
        "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
      display: "Solicitud de Información",
    },
  ];

/*Credigas*/
const dataSelectCredigas = [
  {
    value: "Deseo realizar servicios de instalación de Cilindros Industriales",
    display: "Instalación/Cotización Cilindros Industriales",
  },
  {
    value: "Deseo realizar compra de combustible de gas propano",
    display: "Compra/Cotización GLP",
  },
  {
    value: "Deseo realizar solicitud de información sobres la obtención de tickets GLP.",
    display: "Compra/Cotización de Ticket",
  },
  {
    value:
      "Deseo realizar soliicitud de información sobres los servicios a ofrecer.",
    display: "Solicitud de Información",
  },

];

/*Nativa*/
const dataSelectNativa = [
  {
    value: "Deseo realizar servicios de instalación de Tanques Gasoil",
    display: "Instalación/Cotización de Tanques Gasoil",
  },
  {
    value: "Deseo realizar compra de combustible blanco",
    display: "Compra/Cotización Combustible Blanco",
  },
  {
    value: "Deseo realizar solicitud de información sobres la obtención de tickets CB.",
    display: "Compra/Cotización de Ticket",
  },
  {
    value:
      "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
    display: "Solicitud de Información",
  },
];

/*Templastisa*/
const dataSelectTemplastisa = [
  {
    value: "Deseo realizar compra de lotes de productos plásticos.",
    display: "Compra/Cotización Productos Plásticos",
  },
  {
    value:
      "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
    display: "Solicitud de Información",
  },
];

/*Docar*/
const dataSelectDocar = [
    {
      value: "Deseo realizar servicios de contratación",
      display: "Servicios de Contración",
    },
    {
      value: "Deseo alquilar o tener más información sobre un terreno/vivienda.",
      display: "Alquiler de Propiedad",
    },
    {
        value: "Deseo comprar o tener más información sobre un terreno/vivienda.",
        display: "Compra de Propiedad",
      },
    {
      value:
        "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
      display: "Solicitud de Información",
    },
  ];
  
/*Oxileno*/
const dataSelectOxileno = [
    {
      value: "Deseo realizar servicios de contratación",
      display: "Servicios de Contración",
    },
    {
        value: "Deseo realizar instalación o obtener cotización sobre cilindros.",
        display: "Instalación/Cotización Cilindros Oxigeno",
      },
      {
        value: "Deseo realizar compra o obtener cotización sobre tarifas de oxígeno.",
        display: "Compra/Cotización Oxígeno",
      },
    {
      value:
        "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
      display: "Solicitud de Información",
    },
  ];
  
  /*Oxileno*/
const dataSelectCoop = [
    {
        value: "Deseo realizar instalación o obtener cotización sobre cilindros.",
        display: "Solitud de Préstamo",
      },
      {
        value: "Deseo realizar una solicitud de mi estado cuenta sobre mis ahorros.",
        display: "Solitud de Estado de Cuenta - Ahorro",
      },
      {
        value: "Deseo realizar una solicitud de mi estado cuenta sobre mi préstamo actual.",
        display: "Solitud de Estado de Cuenta - Préstamo",
      },
    {
      value:
        "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
      display: "Solicitud de Información",
    },
  ];

   /*Movilgas*/
const dataSelectMovilgas = [
    {
        value: "Deseo realizar servicios de contratación",
        display: "Servicios de Contración",
      },
      {
        value: "Deseo realizar instalación o obtener cotización sobre cilindros.",
        display: "Instalación/Cotización Cilindros Gas Propano",
      },
      {
        value: "Deseo realizar compra o obtener cotización sobre tarifas de Gas Propano.",
        display: "Compra/Cotización Gas Propano Residencia",
      },
      {
        value: "Deseo realizar compra o obtener cotización sobre tarifas de Gas Propano.",
        display: "Compra/Cotización Gas Propano Negocio",
      },
    {
      value:
        "Deseo realizar solicitud de información sobres los servicios a ofrecer.",
      display: "Solicitud de Información",
    },
  ];

 /*MailsTo*/
const MailsTo = [
  {
    id: 1,
    mail1: "ventascb@credigasnativa.com",
    mail2: "mercadeo@credigasnativa.com",
    mail3: "serviciotickets@credigasnativa.com",
  },
  {
    id: 3,
    mail1: "templastisa@credigasnativa.com",
  },
  {
    id: 4,
    mail1: "operaciones@docar.com.do",
  },
  {
    id: 6,
    mail1: "oxileno@credigasnativa.com",
  },
  {
    id: 7,
    mail1: "cooperativa@credigasnativa.com",
  },
  {
    id: 8,
    mail1: "movilgas@credigasnativa.com",
  },

  
];


//Functions.
class Company {
  GetDataCmb(option) {
    switch (option) {
      case 1:
        this.cmbServices = dataSelectCredigas;
        break;
      case 2:
        this.cmbServices = dataSelectNativa;
        break;
      case 3:
        this.cmbServices = dataSelectTemplastisa;
        break;
      case 4:
        this.cmbServices = dataSelectDocar;
        break;
      case 6:
        this.cmbServices = dataSelectOxileno;
        break;
      case 7:
        this.cmbServices = dataSelectCoop;
        break;
      case 8:
        this.cmbServices = dataSelectMovilgas;
        break;
      default:
        this.cmbServices = dataSelectDefault;
        break;
    }
    return this.cmbServices;
  }

  GetMailTo(cId, cmbIndex) {
    let mail = "info@credigasnativa.com";
    let mainComp = 1;
    let salesIdx = 1;
    let ticketIdx = 3;
    cId = cId === 2 ? mainComp : cId;

    try {
      let e = MailsTo.find((e) => cId === e.id);
      let isMainComp =
        cId === mainComp && (cmbIndex === salesIdx || cmbIndex === ticketIdx);
      mail = !isMainComp ? e.mail1 : cmbIndex === salesIdx ? e.mail2 : e.mail3;
    } catch {}

    return mail;
  }
}

export default Company;


