import './design.css';
import { useState } from 'react';


const Form = () => {
  const [isActive, setIsActive] = useState(false);
  const show = () => {
    setIsActive(current => !current);
  }

  return (
    <main className="main-3">
      <div className="form-container">
        <div className="form-inside">
          <h1>Conctacta Nuestros Agentes</h1>
          <h3>Vía Telefónica</h3>
          <a href="tel:18095965188">+1 (809) 596-5188</a>
          <h3>Escribenos al correo, para</h3>
          <a href="mailto:info@credigasnativa.com">Informaciones Generales</a>
          <a href="mailto:mercadeo@credigasnativa.com">Servicios de Instalación</a>
          <a href="mailto:rhumanos@credigasnativa.com">Recursos Humanos</a>
          <a href="mailto:ventascb@credigasnativa.com">Ventas Generales</a>
        </div>
      </div>

      {/*FORMULARIO PARA ENVIO AUTOMATICO*/}
      {/* 
      <div className='form-container'>
        <form>
          <h1>Conctacta Nuestros Agentes</h1>
          <span>Favor de llenar los campos correspondientes*</span>
          <input type="text" placeholder="Asunto" />
          <textarea id="message" type="text" placeholder="Mensaje" rows={3}/>
          <button style={{backgroundColor: isActive ? "black" : ""} } onClick={show}>Enviar</button>
        </form>
      </div> */}
    </main>
  );
};

export default Form;