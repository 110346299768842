import "./design.css";

import Header from '../sections/header/Header.js';
import Mainbar from '../sections/mainbar/Mainbar.js';
import Logo from "../../resourses/logo/pageinConstruction.png";
import Footer from '../sections/footer/Footer';

const noCreatedPage = () => {
  return (
    <div className="container">
      <Header />
      <Mainbar />
      <main id="constructionDiv" className="main">
        <img src={Logo} alt="Logo" className="l404"></img>
      </main>
      <Footer />
    </div>
  );
};

export default noCreatedPage;
