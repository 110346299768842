import "./design.css";
import $ from "jquery";
import * as React from "react";
import { useState, useEffect } from "react";
import MbSent from "../../utils/Modal/mbSent.tsx";
import Templates from "../../templates/Templates.tsx";
import MbLoading from "../../utils/Modal/mbLoading.js";
import companiesData from "../../utils/companiesUtilities.js";
const URL = "https://apis.credigasnativa.com/api/sendEmail/";

/*Secure Order Form*/
interface InputFormElements extends HTMLFormControlsCollection {
  nameInput: (nameInput: string) => void;
  phoneAreaSelect: (phoneAreaSelect: string) => void;
  phoneInput: (phoneInput: string) => void;
  serviceInput: {
    serviceTitle: (serviceTitle: string) => void;
    serviceBody: (serviceBody: string) => void;
  };
}

interface OrderServicesInputFormElement extends HTMLFormElement {
  readonly elements: InputFormElements;
}
/* -------------------------------------------------------------- */

const delaySend = 3000;
const delayDefault = 1000;

/* -------------------------------------------------------------- */

/*Main*/
function OrderServicesForm({
  companyId,
  companyName,
}: {
  companyId: number;
  companyName: string;
}) {
  /* Variables */
  const [focused, setFocused] = useState(false);
  const [msgStatus, setMsgStatus] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [values, setValues] = useState({
    nameInput: String,
    phoneAreaSelect: String,
    phoneInput: String,
    serviceInput: { serviceTitle: String, serviceBody: String },
  });

  /* Getting Data */
  let cmbidx = 0;
  let renderEmail;
  let _cmbidx = 0;
  let userName = "";
  let _userPhone = "";
  let serviceBody = "";
  let subject = "Solicitud de Producto ";
  let _companyId = companyId === null ? 1 : companyId;
  let _companyName = companyName === null ? "N/A" : companyName;

  /* Functions */
  function handleFocus(event: React.ChangeEvent<HTMLInputElement>) {
    setFocused(true);
  }

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }

  function onChangeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    setValues({
      ...values,
      [event.currentTarget.name]: [
        {
          serviceTitle: event.currentTarget.selectedOptions[0].text,
          serviceBody: event.currentTarget.value,
        },
      ],
    });
    cmbidx = event.currentTarget.selectedIndex;
  }
  function onChangeSelect2(event: React.ChangeEvent<HTMLSelectElement>) {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }

  function phoneFormat(phone: string): string {
    let area = phone.substring(0, 3);
    let first = phone.substring(3, 6);
    let last = phone.substring(6);
    return `(${area}) ${first}-${last}`;
  }

  async function sendMail() {
    /* Consumo de la API de mensajeria */
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: userName,
          userPhone: _userPhone,
          serviceBody: serviceBody,
          subject: subject,
          centerId: _companyId,
          cmbidx: _cmbidx,
        }),
      });
      setMsgStatus(response.ok);
    } catch (error) {
      alert(error);
      return [];
    }
  }
  /* -------------------------------------------------------------- */

  /*Building Data*/
  let cid = parseInt(_companyId.toString());
  let mailto = new companiesData().GetMailTo(companyId, cmbidx);
  let companyData = new companiesData().GetDataCmb(cid);
  /* -------------------------------------------------------------- */

  function handleSubmit(event: React.FormEvent<OrderServicesInputFormElement>) {
    event.preventDefault();

    let serviceTitle = values.serviceInput[0].serviceTitle;
    let userPhone = values.phoneInput.toString();
    let userPhoneArea = values.phoneAreaSelect.toString();
    _companyId = parseInt(`186${companyId}`) ;
    _cmbidx = parseInt(`215${cmbidx}`);
    
    userName = values.nameInput.toString();
    serviceBody = values.serviceInput[0].serviceBody;
    userPhoneArea = parseInt(userPhoneArea) ? userPhoneArea : "809";
    _userPhone = `${userPhoneArea}${userPhone}`;
    _userPhone = phoneFormat(_userPhone);
    subject = `${subject} - ${serviceTitle}`;

    renderEmail = new Templates(
      mailto,
      subject,
      serviceBody,
      userName,
      _userPhone,
      _companyName
    );

    {
      /* Modal Logic */
    }

    if (renderEmail != null) {
      $('button[name="btnServ"').attr("disabled", "disabled");

      if (!statusModal) {
        setStatusModal((statusModal) => true);
      }

      $(".mbLoading").addClass("ShowModalBox");

      sendMail().then(() => {
        $(".mbLoading")
          .removeClass("ShowModalBox")
          .delay(delayDefault)
          .queue(function (next) {
            $(this).addClass("RemoveModalBox");
            next();
          })
          .queue(function (next) {
            $(this).removeClass("ShowModalBox");
            next();
          })
          .queue(function () {
            $(".mbSent")
              .addClass("ShowModalBox")
              .delay(delaySend)
              .queue(function (next) {
                $(this).removeClass("ShowModalBox");
                next();
              })
              .delay(delayDefault)
              .queue(function (next) {
                $(this).addClass("RemoveModalBox");
                next();
              })
              .queue(function (next) {
                $(".serviceForm").addClass("RemoveModalBox");
                $(".endingBanner")
                  .addClass("ShowEndingBanner")
                  .delay(delayDefault)
                  .queue(function (next) {
                    $(this).addClass("ShowModalBox");
                    next();
                  });
                next();
              });
          });
      });
    }
  }

  return (
    <div className="serviceDivForm">
      <form onSubmit={handleSubmit} className="serviceForm">
        {/* Nombre */}
        <div className="formInput">
          <label htmlFor="Nombre">Nombre Completo</label>
          <input
            name="nameInput"
            type="text"
            placeholder="Nombre Completo"
            pattern="^[A-Za-z ]*"
            minLength={5}
            maxLength={25}
            onBlur={handleFocus}
            focused={focused.toString()}
            required
            onChange={onChangeInput}
          />
          <span>Se requiere escribir solo letras del alfabeto.</span>
        </div>

        {/* Telefono */}
        <div className="formInput">
          <label htmlFor="Telefono">Teléfono</label>
          <div className="formInput fiTwo">
            <select
              name="phoneAreaSelect"
              placeholder="Telefono"
              required
              onChange={onChangeSelect2}
            >
              <option value="809">(809)</option>
              <option value="829">(829)</option>
              <option value="849">(849)</option>
            </select>
            <input
              name="phoneInput"
              type="text"
              placeholder="Teléfono"
              pattern="^[0-9]*"
              minLength={7}
              maxLength={7}
              onBlur={handleFocus}
              focused={focused.toString()}
              required
              onChange={onChangeInput}
            />
          </div>
          <input
            hidden
            type="text"
            pattern="^[0-9]*"
            minLength={7}
            maxLength={7}
            onBlur={handleFocus}
            focused={focused.toString()}
            required
            value={values.phoneInput.toString()}
          />
          <span>Se requiere escribir solo números.</span>
        </div>

        {/* Servicio */}
        <div className="formInput">
          <label htmlFor="Servicio">Servicio</label>
          <select
            name="serviceInput"
            placeholder="Telefono"
            required
            onChange={onChangeSelect}
          >
            <option value="">Seleccione un Servicio</option>
            {companyData.map((service, index) => {
              return <option value={service.value}>{service.display}</option>;
            })}
            <span>Se requere llenar este campo.</span>
          </select>
        </div>
        <div className="formInput">
          <button name="btnServ" type="submit">
            Enviar Solicitud
          </button>
        </div>
      </form>
      <div className="endingBanner">
        {msgStatus ? (
          <h3>
            Nuestros agentes se estarán comunicando luego de revisar y procesar
            dicha solicitud
          </h3>
        ) : (
          <h3>
            No se pudo realizar la solicitud! <br />
            Favor llamar directamente al (809) 596-5188 <br />o intentar más
            tarde.
          </h3>
        )}
      </div>

      {/* Modal */}
      <div
        className="mbLoading"
        style={{ display: !statusModal ? "none" : "" }}
      >
        <MbLoading />
      </div>
      <div className="mbSent" style={{ display: !statusModal ? "none" : "" }}>
        <MbSent sent={msgStatus} />
      </div>
    </div>
  );
}

export default OrderServicesForm;
