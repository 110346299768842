import Header from '../sections/header/Header.js';
import Mainbar from '../sections/mainbar/Mainbar.js';
import Centers from './centers.js';
import Footer from '../sections/footer/Footer';

const Stations = () => {
  return (
    <div className="container">
      <Header />
      <Mainbar />
      <Centers />
      <Footer />
    </div>
  );
};

export default Stations;