import './design.css';
import menuLogo from '../../../resourses/logo/menu.png';
import { useState } from 'react';

const Mainbar = () => {
  const [isActive, setIsActive] = useState(false);
  const showMenu = () => {
    setIsActive(current => !current);
  }

    return (
      <mainbar>
        <ul className="menu">
          <li>
            <a href="/" className="main-list-right">
              Inicio
            </a>
          </li>
          <li>
            <a href="/estaciones" className="main-list-right">
              Estaciones
            </a>
          </li>
          <li>
            <a href="/empresas" className="main-list-right">
            Servicios/Empresas
            </a>
          </li>
          <li>
            <a href="/cooperativa" className="main-list-right">
              Cooperativa
            </a>
          </li>
          <li>
            <a href="/supermercado" className="main-list-right">
              Supermercado
            </a>
          </li>
          <li>
            <a href="/historia" className="main-list-right">
              Historia
            </a>
          </li>
          <li>
            <a href="/contacto">Contacto</a>
          </li>
        </ul>
        <a
          id="btnMobile"
          onClick={showMenu}
          style={{ display: isActive ? "none" : "" }}
        >
          <img src={menuLogo}></img>
        </a>
        <div
          id="divMobile"
          style={{ display: isActive ? "block" : "" }}
          className="mobile-menu"
        >
          <ul>
            <li>
              <a href="/" className="main-list-right">
                Inicio
              </a>
            </li>
            <li>
              <a href="/estaciones" className="main-list-right">
                Estaciones
              </a>
            </li>
            <li>
              <a href="/empresas" className="main-list-right">
               Servicios/Empresas
              </a>
            </li>
            <li>
              <a href="/cooperativa" className="main-list-right">
                Cooperativa
              </a>
            </li>
            <li>
              <a href="/supermercado" className="main-list-right">
                Supermercado
              </a>
            </li>
            <li>
              <a href="/historia" className="main-list-right">
                Historia
              </a>
            </li>
            <li>
              <a href="/contacto">Contacto</a>
            </li>
          </ul>
        </div>
      </mainbar>
    );
}

export default Mainbar;