import './design.css';
import OficialPrice from './OficialPrice.js';
import DiscountPrice from "./DiscountPrice.js";
import Map from "./Map.js";

const Main = () => {
  return (
    <main>
      <h1>Precios Oficiales</h1>
      <div className="price-section">
        <OficialPrice />
      </div>
      <h1>Descuento Nativa</h1>
      <div className="price-section">
        <DiscountPrice />
      </div>
      <h1>Estaciones de Combustible </h1>
      <div className='map-section'>
        <Map />
      </div>
    </main>
  );
};

export default Main;


