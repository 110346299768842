import React, {Component} from "react";
const today = new Date();

const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

Date.prototype.addDays = function(days){
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() + days);
  return date;
}

function getWeekStart() {
  const dayWeek = today.getDay();
  const saturday = 6;
  const week = 7;
  let days = (dayWeek < 6) ? (saturday - dayWeek) - week : (dayWeek - saturday);
  return today.addDays(days).getDate();
}

function getWeekEnd(){
  let day = getWeekStart();
  let month = today.getMonth();
  let year = today.getFullYear();
  let newDate = new Date(year, month, day);
  return newDate.addDays(6).getDate();
}

class DateRange extends React.Component {
  constructor() {
    super();
    this.state = {
      weekStart: getWeekStart(),
      weekEnd: getWeekEnd(),
      month: monthNames[today.getMonth()],
      year: today.getFullYear(),
    };
  }

  render() {
    return (
      <div className="price-section-inside-content-title">
        <p className="price-section-header-1">
          Precios del Combustible <br></br>
          Semana del {this.state.weekStart} al {this.state.weekEnd} de{" "}
          {this.state.month}, {this.state.year}
        </p>
        <p className="price-section-header-2">República Dominicana</p>
      </div>
    );
  }
}


export default DateRange;