import './design.css';
import React, { Component } from "react";
import background from "../../resourses/logo/priceLogo.jpg";
const URL = "https://apis.credigasnativa.com/api/nativa/products";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    this.GetData();
  }

  GetData() {
    try {
      fetch(URL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) =>
        response.json().then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        )
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Cargando...</div>;
    } else {
      items.pop();
      return (
        <div className="price-section-inside">
          <div>
            <table>
              <tr>
                <th rowSpan={3} className="vertical-line">
                  Producto
                </th>
                <th colSpan={4} className="horizontal-line">
                  Precio
                </th>
              </tr>
              <tr>
                <th colSpan={3} className="horizontal-line">
                  AutoServicio
                </th>
              </tr>
              <tr className="horizontal-line">
                <th scope="col" className="vertical-line">
                  Efectivo (-10)
                </th>
                <th scope="col" className="vertical-line">
                  Tarjeta (-5)
                </th>
                <th scope="col">40+ Galones (-15)</th>
              </tr>
    
              {items.map((item) => (
                <tr className="centerText">
                  <td>{item.name}</td>
                  <td>{(item.price - 10)}</td>
                  <td>{(item.price - 5)}</td>
                  <td>{(item.price - 15)}</td>
                </tr>
              ))}
            </table>
          </div>
          <img className="price-section-inside-background" src={background}></img>
        </div>
      );

    }
  }
}

export default Products;
