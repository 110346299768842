import './design.css';

const history = () => {
  return (
    <main id="history" className="main-2">
      <h1 className="historyContent">Quienes Somos</h1>
      <p className="historyContent">
        El Grupo Credigas inicia sus actividades el 29 de septiembre del 1973,
        logrando un inmediato crecimiento constante y progresivo gracias a la
        ardua labor de un equipo que tenía como objetivo brindarles seguridad y
        buen servicio a las familias dominicanas. Rápidamente Credigas logro un
        posicionamiento preferencial dentro del mercado de derivados del
        petróleo, gracias a su suplidor permanente la Refinería Dominicana de
        Petróleo <b>(REFIDOMSA)</b>, cuya eficiencia y seguridad hacen posible este
        crecimiento. Para el año 2000, la empresa se expande y nace la marca de
        gasolina Nativa dominicana y pasa a llamarse el Grupo Credigas Nativa.
        Un grupo especializados en la distribución de productos orientados al
        cliente con servicios cada vez más rápidos de Gas Propano y
        Combustibles.
      </p>
    </main>
  );
};

export default history;