import React from "react";
import './mbLoading.css';


const mbLoading = () => {

    return (
        <div className="modal">
          <div className="modal-content">
            <h2>Cargando</h2>
            <div class="dot-pulse"></div>
          </div>
        </div>
    );
}

export default mbLoading;