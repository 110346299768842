import React, { useState } from "react";
import "./design.css";
import OrdersForm from "../services/orderForm.tsx";
import CompaniesGenerals from "../../utils/companiesGenerals";

let companyId = 0;
let companyName = "";

const Menu = () => {
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState("");

  const showForm = (event) => {
    setIsActive((current) => !current);
    setMessage(event.alt);
    companyId = event.target.alt;
    companyName = event.target.name;
  };

  //Getting Data.
  let companies = new CompaniesGenerals();

  return (
    <main className="main-2">
      <h1 className="contain-header">Servicios / Empresas</h1>

      <div className="contain">
        <p className="companiesFormPTag">
          Para realizar una solicitud de servicio, por favor, seleccione el logo
          de la empresa correspondiente:{" "}
        </p>

        {companies.map((company, index) => (
          <div>
            <div className="companies">
              <div
                className={
                  isActive && index + 1 == companyId
                    ? "btnPhoto btnServicies"
                    : "btnPhoto "
                }
                onClick={showForm}
              >
                <img
                  id="btn"
                  src={company.url}
                  alt={company.id}
                  name={company.name}
                ></img>
              </div>
              <div className="ServiceDescription">
                <table>
                  <tr>
                    <td>Empresa</td>
                    <td>
                      <b>{company.name}</b>.
                    </td>
                  </tr>
                  <tr>
                    <td>Productos</td>
                    <td>
                      <b>{company.products}</b>.
                    </td>
                  </tr>
                  <tr>
                    <td>Servicios</td>
                    <td>
                      <b>{company.services}</b>.
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              className={
                isActive && index + 1 == companyId
                  ? "OrdersForm orderFormOn"
                  : "OrdersForm orderFormOff"
              }
            >
              <OrdersForm
                companyId={companyId != 0 ? companyId : 1}
                companyName={companyName}
              />
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />
    </main>
  );
};

export default Menu;
