import './design.css';
import center01 from '../../resourses/centers/center01.png';
import center02 from '../../resourses/centers/center02.png';
import center03 from '../../resourses/centers/center03.png';
import center04 from '../../resourses/centers/center04.png';
import center05 from '../../resourses/centers/center05.png';

import center06 from '../../resourses/centers/center06.png';
import center07 from '../../resourses/centers/center07.png';
import center08 from '../../resourses/centers/center08.png';
import center09 from '../../resourses/centers/center09.png';
import center10 from '../../resourses/centers/center10.png';

import center11 from '../../resourses/centers/center11.png';
import center12 from '../../resourses/centers/center12.png';
import center13 from '../../resourses/centers/center13.png';
import center14 from '../../resourses/centers/center14.png';
import center15 from '../../resourses/centers/center15.png';

import center16 from '../../resourses/centers/center16.png';
import center17 from '../../resourses/centers/center17.png';
import center18 from '../../resourses/centers/center18.png';
import center19 from '../../resourses/centers/center19.png';
import center20 from '../../resourses/centers/center20.png';

import center21 from '../../resourses/centers/center21.png';
import center22 from '../../resourses/centers/center22.png';
import center23 from '../../resourses/centers/center23.png';
import center24 from '../../resourses/centers/center24.png';

import center25 from '../../resourses/centers/center24.png';
import center26 from '../../resourses/centers/center24.png';

let centers = [
    {name: "La Pelona", url: center01, addr: "Carretera Mella, Esq. Calle La Pelona, Cancino I.", location: "https://www.google.com/maps?cid=17866525060316140794"},
    {name: "Mendoza", url: center02, addr: "Carretera Mendoza, Esq. Calle 19.", location: "https://www.google.com/maps?cid=8439839504993525788"},
    {name: "Los Tres Brazos", url: center03, addr: "Prolongación Venezuela.", location: "https://www.google.com/maps?cid=2835772761379663185"},
    {name: "San Isidro", url: center04, addr: "Carretera Mella, Km 16, El Bonito.", location: "https://www.google.com/maps?cid=15305346828005069208"},
    {name: "Cruce de Guerra", url: center05, addr: "Carretera Mella, Km 20, Cruce Mojara.", location: "https://www.google.com/maps?cid=10505103053513790183"},
    
    {name: "Almirante", url: center06, addr: "Carretera Mella, Km 13, El Almirante.", location: "https://www.google.com/maps?cid=5796929019828635593"},
    {name: "Las Américas", url: center07, addr: "Marginal las Américas, Los Frailes.", location: "https://www.google.com/maps?cid=5535524895006348368"},
    {name: "Toronja", url: center08, addr: "Av. La Pista, Entrada La Toronja.", location: "https://www.google.com/maps?cid=1210708181707330872"},
    {name: "Paseo Oriental", url: center09, addr: "Autopista San Isidro, Urb. Fernández.", location: "https://www.google.com/maps?cid=2913099383698827374"},
    {name: "Los Maestros", url: center10, addr: "Autopista San Isidro, Esq. Av. Hipódromo 5to. Centeanrio, Los Maestros.", location: "https://www.google.com/maps?cid=4978582838603267453"},

    {name: "La Joya", url: center11, addr: "Cruce Carretera Antonio Guerra, La Joya.", location: "https://www.google.com/maps?cid=8815144397894030063"},
    {name: "La Privada", url: center12, addr: "Av. La Pista, Mendoza.", location: "https://www.google.com/maps?cid=2568601202019070012"},
    {name: "Padre Castellanos", url: center13, addr: "Av. Padre Castellanos, Esq. Calle 4 Norte.", location: "https://www.google.com/maps?cid=11591978261127722073"},
    {name: "Villa Mella", url: center14, addr: "Carretera Yamasa, Km 14.5, San Felipe, Villa Mella.", location: "https://www.google.com/maps?cid=1580571779291521722"},
    {name: "Haina", url: center15, addr: "Carretera Sánchez, Villa Lisa.", location: "https://www.google.com/maps?cid=577409103641741786"},
    
    {name: "La Cumbre", url: center16, addr: "Autopista Duarte, Km 63, La Cumbre.", location: "https://www.google.com/maps?cid=16753060483471824567"},
    {name: "Manogüayabo", url: center17, addr: "Carretera Manogüayabo, Esq. Cruce Calle Hato Nuevo", location: "https://www.google.com/maps?cid=13629389693820223410"},
    {name: "Pantoja", url: center18, addr: "Autopista Duarte, Entrada Pantoja.", location: "https://www.google.com/maps?cid=15067212672898955425"},
    {name: "Km 22", url: center19, addr: "Autopista Duarte, Km 22.", location: "https://www.google.com/maps?cid=12446589757240757604"},
    {name: "Hato Nuevo", url: center20, addr: "Carretera Hato Nuevo, Los Alcarrizos.", location: "https://www.google.com/maps?cid=370255950354091980"},
    
    {name: "San Victor (Moca)", url: center21, addr: "Carretera San Victor, Km 5, Moca.", location: "https://www.google.com/maps?cid=1733126354202783640"},
    {name: "Jacagua (Santiago)", url: center22, addr: "Jacagua-Santiago, Km 3.", location: "https://www.google.com/maps?cid=1260891931710588527"},
    {name: "Licey (Santiago)", url: center23, addr: "Carretera Duarte, Santiago, Licey Pontesuela.", location: "https://www.google.com/maps?cid=1154791605786252503"},
    {name: "Canela (Santiago)", url: center24, addr: "Carretera Santiago, Las Canelas, Km 16.", location: "https://www.google.com/maps?cid=6219836369583428503"},
    
    {name: "Jubey" , url: center25, addr: "Carretera Mella, Km 39, Esq. Carretera Jubey-Boca Chica.", location: "https://www.google.com/maps?cid=2616120153420746832"},
    {name: "Caleta", url: center26, addr: "Marginal las Américas Km 26, La Caleta.", location: "https://www.google.com/maps/dir//18.4500783,-69.6679772"},
];


let stations = () => {
  return (
    <main className='main-2'>
      <h1 className='contain-header'>Estaciones Nativa</h1>
      <div className='contain'>
        {centers.map((center, index) => (
          <div className="stations">
            <div className="photo">
              <img className="imgPhoto" src={center.url}></img>
            </div>
            <div className="description">
              <p className="title">
                {index + 1}. Nativa {center.name}
              </p>
              <p>{center.addr}</p>
              <a href={center.location} className='map-link'>Ver en el Mapa</a>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
};

export default stations;