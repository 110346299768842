class Templates {
  //Atributes.
  mailto: string;
  subject: string;
  body: string;
  userName: string;
  userPhone: string;
  companiName: string;

  //Cosntructor.
  constructor(
    mailto: string,
    subject: string,
    body: string,
    userName: string,
    userPhone: string,
    companiName: string
  ) {
    this.mailto = mailto;
    this.subject = subject;
    this.body = body;
    this.userName = userName;
    this.userPhone = userPhone;
    this.companiName = companiName;
  }

  //Funtions.
  phoneFormat(phone: string): string {
    let area = phone.substring(0, 3);
    let first = phone.substring(3, 6);
    let last = phone.substring(6);
    return `(${area}) ${first}-${last}`;
  }

  Orders(): string {
    return (
      "mailto:" +
      this.mailto +
      "?subject=" +
      this.subject + " - " + this.companiName + 
      "&body=" +
      "Saludos! " +
      "%0D%0A" +
      "%0D%0A" +
      "Mi nombre es " +
      this.userName +
      ", %0D%0A" +
      this.body +
      "%0D%0A" +
      "Mi Teléfono es " +
      this.phoneFormat(this.userPhone) +
      "." +
      "%0D%0A" +
      "%0D%0A" +
      "Gracias" +
      "%0D%0A" +
      "Enviado desde el Sitio Web del Grupo Credigas Nativa. "
    );
  }
}

export default Templates;
