import React, {useState} from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import {Home} from './components/';
import NoPage from './components/noPage/NoPage.js';
import PageInConstruction from './components/noCreatedPage/noCreatedPage.js';
import Companies from './components/companies/Companies.js';
import Stations from './components/stations/stations.js';
import About from './components/about/about.js';
import Contact from './components/contact/Contact.js';

import './App.css';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/servicios" element={<PageInConstruction />} />
        <Route path="/estaciones" element={<Stations />} />
        <Route path="/empresas" element={<Companies />} />
        <Route path="/cooperativa" element={<PageInConstruction />} />
        <Route path="/supermercado" element={<PageInConstruction/>}/> 
        <Route path="/historia" element={<About />} />
        <Route path="/contacto" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;






















