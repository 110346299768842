import Header from '../sections/header/Header.js';
import Mainbar from '../sections/mainbar/Mainbar.js';
import Gallery from './gallery/Gallery.js';
import Main from './Main.js';
import Footer from '../sections/footer/Footer';

const Home = () => {
  return (
    <div className="container">
      <Header />
      <Mainbar />
      <Gallery />
      <Main />
      <Footer />
    </div>
  );
};

export default Home;