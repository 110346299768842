import './design.css';
import Header from '../sections/header/Header.js';
import Mainbar from '../sections/mainbar/Mainbar.js';
import Form from './contactForm.js';
import Footer from '../sections/footer/Footer';


let Contact = () =>{
    return(
        <div className='container'>
            <Header/>
            <Mainbar/>
            <Form/>
            <Footer/>
        </div>
    );

}

export default Contact;