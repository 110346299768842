import Header from '../sections/header/Header.js';
import Mainbar from '../sections/mainbar/Mainbar.js';
import History from './history.js';
import Footer from '../sections/footer/Footer';

const about = () => {
  return (
    <div id="about" className="container">
      <Header />
      <Mainbar />
      <History />
      <Footer />
    </div>
  );
};

export default about;