import './design.css';
import React from 'react';
import { useState, useEffect } from 'react';
import photo1 from '../../../resourses/gallery/1.jpg';
import photo2 from '../../../resourses/gallery/2.jpg';
import photo3 from '../../../resourses/gallery/3.jpg';
import photo4 from '../../../resourses/gallery/4.jpg';
import photo5 from '../../../resourses/gallery/5.jpg';
import photo6 from '../../../resourses/gallery/6.jpg';
import photo7 from '../../../resourses/gallery/7.jpg';
import photo8 from '../../../resourses/gallery/8.jpg';
import photo9 from '../../../resourses/gallery/9.jpg';

let images = [
  { url: photo1, class: "gallery-item gallery-item-1" },
  { url: photo2, class: "gallery-item gallery-item-2" },
  { url: photo3, class: "gallery-item gallery-item-3" },
  { url: photo4, class: "gallery-item gallery-item-4" },
  { url: photo5, class: "gallery-item gallery-item-5" },
  { url: photo6, class: "gallery-item gallery-item-6" },
  { url: photo7, class: "gallery-item gallery-item-7" },
  { url: photo8, class: "gallery-item gallery-item-8" },
  { url: photo9, class: "gallery-item gallery-item-9" },
];

const delay = 4000;

const Slideshow = () => {
  const [time, setTime] = useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());

    }, delay);

    return () => {
      clearInterval(interval);
    };
  }, 
  updateGallery());


  function updateGallery() {
    images.push(images.shift());
    images.push(images.shift());
    images.push(images.shift());
    images[0].url = photo1;
    images[1].url = photo2;
    images[2].url = photo3;
    images[3].url = photo4;
    images[4].url = photo5;
    images[5].url = photo6;
    images[6].url = photo7;
    images[7].url = photo8;
    images[8].url = photo9;
  }

  return (
    <gallery>
      <div className="gallery">
        <div className="gallery-container">
          <img className={images[0].class} src={images[0].url}></img>
          <img className={images[1].class} src={images[1].url}></img>
          <img className={images[2].class} src={images[2].url}></img>
          <img className={images[3].class} src={images[3].url}></img>
          <img className={images[4].class} src={images[4].url}></img>
          <img className={images[5].class} src={images[5].url}></img>
          <img className={images[6].class} src={images[6].url}></img>
          <img className={images[7].class} src={images[7].url}></img>
          <img className={images[8].class} src={images[8].url}></img>
        </div>
      </div>
    </gallery>
  );
};
export default Slideshow;


