import ptcCredigas from '../resourses/logo/btnCredigasLogo.PNG';
import ptcNativa from '../resourses/logo/btnNativaLogo.PNG';
import ptcTemplastisa from '../resourses/logo/btnTemplastisaLogo.PNG';
import ptcTanques from '../resourses/logo/btnTanquesLogo.PNG';
import ptcNativo from '../resourses/logo/btnNativoLogo.PNG';
import ptcOjo from '../resourses/logo/btnOjoVisorLogo.PNG';
import ptcBasur from '../resourses/logo/btnBasurLogo.PNG';
import ptcParqueo from '../resourses/logo/btnParqueoLogo.PNG';
import ptcDocar from '../resourses/logo/btnDocarLogo.PNG';
import ptcRaton from '../resourses/logo/btnRatonLogo.PNG';
import ptcOxileno from '../resourses/logo/btnOxilenoLogo.PNG';
import ptcIsidro from '../resourses/logo/btnSanIsidroLogo.PNG';
import ptcCarWash from '../resourses/logo/btnWashLogo.PNG';
import ptcPlot from '../resourses/logo/btnPlotLogo.PNG';
import ptcCoop from '../resourses/logo/btnCoopLogo.PNG';
import ptcFundacion from '../resourses/logo/btnFundacionLogo.PNG';

class CompaniesGenerals {
  constructor() {
    this.companies = [
      {
        id: 1,
        name: "Credigas",
        url: ptcCredigas,
        services: "Cotización, Ventas, Instalación y Distribución",
        products: "Gas Propano, Cilindro Industrial",
      },
      {
        id: 2,
        name: "Nativa",
        url: ptcNativa,
        services: "Cotización, Ventas, Tickets y Distribución",
        products:
          "Gasolina Premium, Gasolina Regular, Diesel Optium, Diesel Regular",
      },
      {
        id: 3,
        name: "Templastisa",
        url: ptcTemplastisa,
        services: "Cotización, Ventas y Distribución",
        products: "Utensilios Plásticos",
      },
      {
        id: 4,
        name: "Docar",
        url: ptcDocar,
        services: "Alquiler y Ventas",
        products: "Terrenos",
      },
      {
        id: 5,
        name: "San Isidro Country Club & Golf",
        url: ptcIsidro,
        services: "Alquiler",
        products: "Espacio Recreación",
      },
      {
        id: 6,
        name: "Oxileno",
        url: ptcOxileno,
        services: "Cotización, Ventas, Alquiler y Distribución",
        products: "Oxígeno, Cilindros",
      },
      {
        id: 7,
        name: "Cooperativa de Ahorro, Credito y Serv. Multiple",
        url: ptcCoop,
        services: "Cooperativa",
        products: "Acuerdos",
      },
      {
        id: 8,
        name: "Tanques del Caribe",
        url: ptcTanques,
        services: "Cotización, Ventas, Alquiler y Distribución",
        products: "Gas Propano, Cilindro Industrial",
      },
      {
        id: 9,
        name: "Nativo Dream Cafe",
        url: ptcNativo,
        services: "Ventas",
        products: "Snacks, Bebidas y Golosinas",
      },
      {
        id: 10,
        name: "Seguridad Ojo Visor",
        url: ptcOjo,
        services: "Contrataciones",
        products: "Recurso Humano",
      },
      {
        id: 11,
        name: "Basura Urbana",
        url: ptcBasur,
        services: "Contrataciones",
        products: "Servicios Limpieza",
      },
      {
        id: 12,
        name: "Dominicana de Parqueos",
        url: ptcParqueo,
        services: "Alquiler",
        products: "Espacio Parqueo",
      },
      {
        id: 13,
        name: "Transporte El Ratón",
        url: ptcRaton,
        services: "Contrataciones",
        products: "Vehículos",
      },
      {
        id: 14,
        name: "Nativo Automático Car Wash",
        url: ptcCarWash,
        services: "Lavados de Vehículos",
        products: "Servicios",
      },
      {
        id: 15,
        name: "All Plot Corp",
        url: ptcPlot,
        services: "Servicios Inmobiliarios",
        products: "Acuerdos",
      },
      {
        id: 16,
        name: "Fundación Ayuda Comunitaria JV",
        url: ptcFundacion,
        services: "Fundación",
        products: "Fundación",
      },
    ];
    return this.companies;
  }
}
 
export default CompaniesGenerals;


