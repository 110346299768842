import './design.css';
import React, { Component } from "react";
import background from "../../resourses/logo/priceLogo.jpg";
import DateRange from "../../utils/DateRange.js";
const URL = "https://apis.credigasnativa.com/api/nativa/products";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    this.GetData();
  }

  GetData() {
    try {
      fetch(URL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) =>
        response.json().then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        )
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Cargando...</div>;
    } else {
      return (
        <div className="price-section-inside">
          <div className="price-section-inside-content">
            <DateRange />
            <div className="price-section-inside">
              <div>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Precio Oficial</th>
                  </tr>
                  {items.map((item) => (
                    <tr className="centerText">
                      <td>{item.name}</td>
                      <td>{item.price}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>

          <img
            className="price-section-inside-background"
            src={background}
          ></img>
        </div>
      );
    }
  }
}

export default Products;