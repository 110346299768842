import fireLogo from '../../../resourses/logo/mainLogo.gif';
import './design.css';

let Header = () => {
    return (
      <header>
        <div>
          <a href='/'>
            <img src={fireLogo} className="App-logo" alt="logo" />
          </a>
        </div>
      </header>
    );
}

export default Header;